import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { AssetLeaseTerm } from '../models/asset-lease-terms';
import {
    Business,
    FormPayload,
    Individual,
} from '../models/individual-business';
import { ProductDetails } from '../models/product-details';

interface CreateContractStepsState {
    counterPartyProfile: Individual | Business;
    counterPartyPaymentChannel: {
        recordCreator: string;
    };
    assetLeasingTerms: AssetLeaseTerm;
    selectedProduct: Partial<ProductDetails>;
}

const initialState: CreateContractStepsState = {
    counterPartyProfile: {
        firstName: 'Liang Wenfeng',
        partyType: 'Individual',
    },
    counterPartyPaymentChannel: {
        recordCreator: 'ab0058c5-69de-4a5f-9329-12f9e2d8b57e',
    },
    assetLeasingTerms: {
        leaseAmount: '500000',
        paymentCycle: 'Monthly',
        startDate: '10/01/2024',
        endDate: '10/01/2025',
        downPayment: '10000',
        fees: '200',
    },
    selectedProduct: {
        currency: 'INR',
    },
};

export const AccountStore = signalStore(
    withState(initialState),
    withMethods((store) => ({
        setCounterPartyProfile(
            counterPartyProfile: Individual | Business
        ): void {
            patchState(store, { counterPartyProfile });
        },
        setCounterPartyPaymentChannel(recordCreator: string): void {
            patchState(store, (state) => ({
                counterPartyPaymentChannel: {
                    ...state.counterPartyPaymentChannel,
                    recordCreator,
                },
            }));
        },
        setAssetLeasingTerms(assetLeasingTerms: AssetLeaseTerm): void {
            patchState(store, { assetLeasingTerms });
        },
        updateSelectedProduct(selectedProduct: ProductDetails): void {
            patchState(store, { selectedProduct });
        },
    }))
);
