export function sha256(plain: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
}

export async function generateCodeChallenge(
    codeVerifier: string
): Promise<string> {
    const hash = await sha256(codeVerifier);
    return btoa(String.fromCharCode(...new Uint8Array(hash)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
}

export function getRandomString(length: number): string {
    const charset =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let result = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        result += charset.charAt(randomIndex);
    }
    return result;
}

export function filterFormFields(
    formValue: any,
    allowedFields: (string | number | symbol)[]
): any {
    return Object.fromEntries(
        Object.entries(formValue).filter(([key]) => allowedFields.includes(key))
    );
}
