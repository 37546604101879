import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { ProductDetails } from '../models/product-details';
import { delay, Observable, of } from 'rxjs';
import { ContractDetails } from '../models/contract-details';
import { Network } from '../components/contract-creation-steps/counter-party-payment-channel/counter-party-payment-channel.component';

// Sample data for product categories
const LIST_OF_PRODUCTS: ProductDetails[] = [
    {
        productName: 'Asset lease',
        currency: 'CHF',
        contractType: 'ANN',
        productCategoryName: 'Lease',
        productDescription: `A car or bike lease's monthly EMI (Equated Monthly Installment) involves a
    few factors, including the loan amount, interest rate, and loan tenure.
    The EMI is the fixed payment amount made monthly throughout the lease
    term.`,
    },
    {
        productName: 'Car lease',
        currency: 'CHF',
        contractType: 'ANN',
        productCategoryName: 'Lease',
        productDescription: `A car or bike lease's monthly EMI (Equated Monthly Installment) involves a
    few factors, including the loan amount, interest rate, and loan tenure.
    The EMI is the fixed payment amount made monthly throughout the lease
    term.`,
    },
    {
        productName: 'Borrowing',
        currency: 'CHF',
        contractType: 'ANN - RPL',
        productCategoryName: 'Loan',
        productDescription: `A car or bike lease's monthly EMI (Equated Monthly Installment) involves a
    few factors, including the loan amount, interest rate, and loan tenure.
    The EMI is the fixed payment amount made monthly throughout the lease
    term.`,
    },
    {
        productName: 'Grain forwards',
        currency: 'USD',
        contractType: 'COM & FDW',
        productCategoryName: 'Forwards',
        productDescription: `Commodity forwards are financial contracts that obligate the buyer to purchase, and
    the seller to sell, a specific quantity of a commodity at a predetermined price on a
    future date.`,
    },
    {
        productName: 'Current account',
        currency: 'CHF',
        contractType: 'UMP',
        productCategoryName: 'Savings',
        productDescription: `Digital savings accounts offered by financial technology (fintech) companies or
    neo-banks`,
    },
    {
        productName: 'Shadow account',
        currency: 'ETH',
        contractType: 'COM',
        productCategoryName: 'Crypto custody',
        productDescription: `Cryptocurrency custody prioritize security measures to protect digital assets from
    theft, hacking, or unauthorized access.`,
    },
];

// Sample data for categorized contracts

const CONTRACT_LIST: ContractDetails[] = [
    {
        balance: '$400.0',
        contractId: 'MFF/123-1',
        status: 'Predeal',
        productName: 'Personal savings',
    },
    {
        balance: '$500.0',
        contractId: 'MFF/133-1',
        status: 'Default',
        productName: 'Loan',
    },
    {
        balance: '$600.0',
        contractId: 'MFF/143-1',
        status: 'Performant',
        productName: 'Bond',
    },
];

const networkList: Network[] = [
    {
        networkName: 'System',
        networkCurrency: 'USD',
        wallets: [
            {
                currency: 'USD',
                address: 'ab0058c5-69de-4a5f-9329-12f9e2d8b57e',
                balance: '0.00',
                walletName: 'Wallet-1',
            },
            {
                currency: 'USD',
                address: '2b116b39-d39d-49a0-8126-c51bf0705489',
                balance: '0.00',
                walletName: 'Wallet-2',
            },
        ],
        networkImageUrl: 'S',
        networkType: 'Transaction',
    },
    {
        networkName: 'Ethereum',
        networkCurrency: 'ETH',
        wallets: [
            {
                currency: 'ETH',
                address: '0x8A662674e1550Bddb055eDc0cD7b1eDdEeDa5B60',
                balance: '0.00',
                walletName: 'Wallet-1',
            },
            {
                currency: 'ETH',
                address: '0x660e3B2b1EA0926B6fa040c409e9E24D0529FEA9',
                balance: '0.00',
                walletName: 'Wallet-2',
            },
        ],
        networkImageUrl: 'E',
        networkType: 'Transaction',
    },
    {
        networkName: 'AVAX',
        wallets: [
            {
                currency: 'ETH',
                address: '0x8A662674e1550Bddb055eDc0cD7b1eDdEeDa5B60',
                balance: '0.00',
                walletName: 'Wallet-1',
            },
            {
                currency: 'ETH',
                address: '0x660e3B2b1EA0926B6fa040c409e9E24D0529FEA9',
                balance: '0.00',
                walletName: 'Wallet-2',
            },
        ],
        networkImageUrl: 'A',
        networkType: 'Transaction',
        networkCurrency: 'USD',
    },
    {
        networkName: 'Casper',
        wallets: [
            {
                currency: 'CSPR',
                address: 'bf4cf350-1692-4c8e-a81f-5cf02f5bcf1d',
                balance: '0.00',
                walletName: 'Wallet-1',
            },
            {
                currency: 'CSPR',
                address: 'e6aa10c5f7596cfaeb2f511aab19c930fc',
                balance: '0.00',
                walletName: 'Wallet-2',
            },
        ],
        networkImageUrl: 'C',
        networkType: 'Tokenization',
        networkCurrency: 'CSPR',
        optionalCurrency: 'all',
    },
    {
        networkName: 'A-Block',
        wallets: [
            {
                currency: 'ABC',
                address: '8e8fd6f71202dcf7d572e1dff34d343a99479f3a',
                balance: '0.00',
                walletName: 'Wallet-1',
            },
            {
                currency: 'ABC',
                address: '785c9a04b4b07697ae27f827fc3b8',
                balance: '0.00',
                walletName: 'Wallet-2',
            },
        ],
        networkImageUrl: 'A',
        networkType: 'Tokenization',
        networkCurrency: 'ABC',
        optionalCurrency: 'all',
    },
    {
        networkName: 'IBAN',
        wallets: [
            {
                currency: 'CHF',
                address: 'CH93 0076 2011 6238 5295 7',
                balance: '0.00',
                walletName: 'Wallet-1',
            },
            {
                currency: 'CHF',
                address: 'CH94 0098 2011 6265 5278 9',
                balance: '0.00',
                walletName: 'Wallet-2',
            },
        ],
        networkImageUrl: 'I',
        networkType: 'Transaction',
        networkCurrency: 'CHF',
    },
    {
        networkName: 'SWIFT',
        wallets: [
            {
                currency: 'USD',
                address: 'BOFAUS6N321 5847854787',
                balance: '0.00',
                walletName: 'Wallet-1',
            },
            {
                currency: 'USD',
                address: 'BOFAUS7N547 9658474541',
                balance: '0.00',
                walletName: 'Wallet-2',
            },
        ],
        networkImageUrl: 'S',
        networkType: 'Transaction',
        networkCurrency: 'USD',
    },
    {
        networkName: 'Binance Pay',
        wallets: [
            {
                currency: 'USD',
                address: '8e8fd6f71202dcf7d572e1dff34d343',
                balance: '0.00',
                walletName: 'Wallet-1',
            },
            {
                currency: 'USD',
                address: '71202dcf7d572e1dff34d343a99479f3a',
                balance: '0.00',
                walletName: 'Wallet-2',
            },
        ],
        networkImageUrl: 'B',
        networkType: 'Transaction',
        networkCurrency: 'USD',
    },
];

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    private readonly contractDetailSignal: WritableSignal<
        ContractDetails | undefined
    > = signal(undefined);

    constructor(private readonly httpClient: HttpClient) {}

    setContractDetail(contract: ContractDetails) {
        this.contractDetailSignal.set(contract);
    }

    getContractDetails(): WritableSignal<ContractDetails | undefined> {
        return this.contractDetailSignal;
    }

    getListOfProducts(): Observable<ProductDetails[]> {
        return of(LIST_OF_PRODUCTS);
        // .pipe(delay(3000));
    }

    getListOfContracts(): Observable<ContractDetails[]> {
        return of(CONTRACT_LIST);
    }

    public getNetworks(
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<Network[]> {
        return of(networkList);
    }
}
